



























































import { Component, Vue } from 'vue-property-decorator';
import { PhoneIcon } from 'vue-feather-icons';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  components: { PhoneIcon },
  metaInfo: metaInfo({
    title: 'Kontakt | Kakadoo',
    description: 'V případě dotazů nebo problémů jsme vám k dispozici nonstop',
    url: buildUrl('/kontakt')
  })
})
export default class Contacts extends Vue {
  trackContact() {
    this.$fbq('track', 'Contact');
  }
}
